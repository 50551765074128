<template>
  <a-menu-item class="flex" :key="item.id" @click.capture="handleLink">
    <!-- <span class="anticon" v-if="routeChildren">
      <icon :icon="routeChildren.meta.icon"></icon>
    </span>
    <div class="round" v-else></div> -->
    <span class="menu-title">{{ routeChildren.name }}</span>
  </a-menu-item>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    routeChildren: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    "$route.path": function (val) {
      console.log(val);
    },
  },
  methods: {
    handleLink() {
      const route = this.routeChildren;
      const { type, path, redirect } = route;
      if (type == "LINK") {
        window.open(path);
      } else if (type == "MENU") {
        this.$router.push(route.path);
      } else {
        if (type == "DIR" && path == "/") {
          this.$router.push(redirect);
        } else {
          console.log();
        }
      }
    },
  },
};
</script>
