import request from "./index";

export function login(data) {
  return request({
    url: "authentication",
    method: "post",
    data,
  });
}

export function getAccountInfo(id) {
  return request({
    url: `/account/${id}`,
    method: "get",
  });
}

export function getUserInfo(id) {
  return request({
    url: `/user/${id}`,
    method: "get",
  });
}

export function getOrganizationInfo(id) {
  return request({
    url: `/organization/${id}`,
    method: "get",
  });
}

export function updateAccountInfo(data) {
  return request({
    url: `/account/${data.id}`,
    method: "patch",
    data,
  });
}

export function changePassword(data) {
  return request({
    url: `/account/${data.id}/password`,
    method: "put",
    data,
  });
}

//获取短信验证码
export function getSmsCode(data) {
  return request({
    url: `/authentication/phone-verification-code`,
    method: "post",
    data,
  });
}

//获取邮箱验证码
export function getEmailCode(data) {
  return request({
    url: `/authentication/email-verification-code`,
    method: "post",
    data,
  });
}

//修改密码接口
export function editPassword(data) {
  return request({
    url: `/authentication/changePwd`,
    method: "post",
    data,
  });
}
