<template>
  <div class="search-wrapper flex">
    <slot></slot>
    <div class="extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<style lang="less" scoped>
.search-wrapper {
  justify-content: space-between;
}
</style>
