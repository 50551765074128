<template>
  <div class="side-menu" :style="sideMenuStyle">
    <a-spin :spinning="spinning" style="min-height: 300px">
      <a-menu
        mode="inline"
        v-model:selectedKeys="selectedKeys"
        :open-keys="openKeys"
        @openChange="onOpenChange"
      >
        <layout-menu
          v-for="route in menus"
          :key="route.id"
          :item="route"
        ></layout-menu> </a-menu
    ></a-spin>
  </div>
</template>

<script>
import { reactive, toRefs, computed, ref, watch, onMounted } from "vue";
import { usePermissionStore } from "@/stores/permission";
// import { useSettingsStore } from "@/stores/settings";
import { useRoute } from "vue-router";

import LayoutMenu from "./components/LayoutMenu.vue";

export default {
  components: {
    LayoutMenu,
  },
  setup() {
    const route = useRoute();
    const store = usePermissionStore();
    // const settingsStore = useSettingsStore();
    const sideMenuStyle = ref({});
    const spinning = ref(false);
    const menus = computed(() => store.menus);
    const state = reactive({
      openKeys: [],
      selectedKeys: [],
    });

    const getKeys = (key) => {
      if (key) {
        const item = store.routes.find((item) => item.id == key);
        if (item.superior) {
          return [item.id].concat(getKeys(item.superior.id));
        }
        return [item.id];
      } else {
        return [];
      }
    };

    const onOpenChange = (openKeys) => {
      if (openKeys.length == 0) {
        state.openKeys = [];
      } else {
        const latestOpenKey = openKeys[openKeys.length - 1];
        if (state.openKeys.includes(latestOpenKey)) {
          state.openKeys.shift();
        } else {
          const keys = getKeys(latestOpenKey);
          state.openKeys = keys;
          sessionStorage.setItem("openKeys", keys);
        }
      }
    };

    const initKeys = () => {
      const pid = route.meta.pid;
      const keys = getKeys(pid);
      state.openKeys = [...keys];
      state.selectedKeys = sessionStorage.getItem("selectedKeys")
        ? sessionStorage.getItem("selectedKeys").split(",")
        : [];
    };

    onMounted(() => {
      initKeys();
    });

    watch(
      () => state.selectedKeys,
      (keys) => {
        sessionStorage.setItem("selectedKeys", keys);
      }
    );

    watch(
      () => route.path,
      () => {
        state.selectedKeys = [route.meta.id];
      },
      {
        immediate: true,
      }
    );

    // watch(
    //   () => settingsStore.theme,
    //   (theme) => {
    //     const {
    //       asideBackgroundType,
    //       asideBackgroundImage,
    //       asideBackgroundColor,
    //     } = theme;
    //     if (asideBackgroundType == "image") {
    //       sideMenuStyle.value = {
    //         background: `url(${asideBackgroundImage}) no-repeat !important`,
    //         "background-size": "cover !important",
    //       };
    //     } else {
    //       sideMenuStyle.value = {
    //         background: `${asideBackgroundColor} !important`,
    //       };
    //     }
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      ...toRefs(state),
      spinning,
      menus,
      sideMenuStyle,
      onOpenChange,
    };
  },
};
</script>

<style lang="less" scoped>
.side-menu {
  position: fixed;
}
</style>
