import { defineStore } from "pinia";

import { login, getOrganizationInfo, getUserInfo } from "@/api/auth";
import { getBasicInfo } from "@/api/expo/company";

export const useUserStore = defineStore("user", {
  persist: {
    storage: sessionStorage,
  },
  state: () => ({
    token: "",
    username: "",
    userInfo: null,
    accountInfo: null,
    exhibitorInfo: null,
    organizationInfo: null,
  }),
  actions: {
    logout() {
      this.$reset();
    },
    resetAccount(account) {
      this.accountInfo = account;
    },
    refreshAccount(account) {
      this.accountInfo.name = account.name;
      const properties = this.accountInfo.properties || {};
      properties.avatar = account.avatar;
      this.accountInfo.properties = properties;
    },
    getInfo() {
      getUserInfo(this.userInfo.id).then((res) => {
        this.organizationInfo = res.organization;
        getOrganizationInfo(res.organization.id).then((response) => {
          const id = response.exhibitors[0].id;
          this.getExhibitorInfo(id);
        });
      });
    },
    onLogin(params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            const { token, user, account } = res;
            this.token = token;
            this.userInfo = user;
            this.accountInfo = account;
            resolve(res);
            // this.getInfo(res);
          })
          .catch((error) => reject(error));
      });
    },
    getExhibitorInfo(id) {
      getBasicInfo(id).then((res) => {
        this.exhibitorInfo = res;
      });
    },
  },
});
