<template>
  <div
    class="btn y-btn"
    :class="[
      `y-btn-${type}`,
      {
        'y-btn-text-primary': type == 'text' && textType == 'primary',
        'y-btn-text-success': type == 'text' && textType == 'success',
        'y-btn-text-disable': type == 'text' && textType == 'disable',
      },
      {
        'y-btn-loading': loading,
      },
    ]"
    @click="handleClick"
  >
    <a-icon type="loading" v-if="loading" style="margin-right: 8px" />
    <slot name="icon"></slot>
    <slot>{{ label }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "default",
    },
    textType: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  width: 92px;
  margin: 0 12px;
  display: inline-block;
  cursor: pointer;
  height: 48px;
  padding: 0 14px;
  text-align: center;
  line-height: 48px;
  background: #35448f;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  img {
    vertical-align: sub;
  }
}

.y-btn-primary {
  background: #157efb;
}

.y-btn-text {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: auto;
  width: auto;
  padding: 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  &-primary {
    color: #157efb;
  }
  &-success {
    color: #41ca5f;
  }
  &-disable {
    color: #cccccc;
  }
}

.y-btn-loading {
  opacity: 0.8;
  pointer-events: none;
}

.y-btn-light {
  height: 48px;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #2979ff;
}
</style>
