<template>
  <header class="page-header">
    <div class="logo">
      <img src="@/assets/images/top/logo.png" style="height: 40px" />
      <span class="title">义耀展会服务运管平台</span>
    </div>
    <div class="header-right">
      <div class="date">{{ dateLabel }}</div>
      <div class="nav back" role="button" @click="goBack">
        <img src="@/assets/images/top/icon-back.png" class="icon" />
        <span class="text">返回</span>
      </div>
      <div class="nav user" role="button">
        <img src="@/assets/images/top/icon-user.png" class="icon" />
        <span class="text">{{ accountInfo.name }}</span>
      </div>
      <div class="nav logout" role="button" @click="logout">
        <img src="@/assets/images/top/icon-logout.png" class="icon" />
        <span class="text">Logout</span>
      </div>
    </div>
  </header>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "pinia";
import { Modal } from "ant-design-vue";

import { setToken } from "@/utils/auth";

import { useUserStore } from "@/stores/user";
import { usePermissionStore } from "@/stores/permission";

export default {
  data() {
    const dateLabel = dayjs().format(`dddd, MMM D, YYYY`);
    return {
      dateLabel,
      userName: "",
    };
  },
  computed: {
    ...mapState(useUserStore, ["accountInfo"]),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      const store = useUserStore();
      const permissionStore = usePermissionStore();
      const _this = this;
      Modal.confirm({
        title: "退出",
        content: "确定退出系统吗？",
        onOk() {
          store.$reset();
          permissionStore.$reset();
          setToken("");
          _this.$router.push("/login");
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  background: #192a87;
  height: 60px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  .logo {
    display: flex;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 60px;
      margin-left: 15px;
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    .nav {
      margin-left: 12px;
      display: flex;
    }
    .date {
      padding-right: 13px;
      border-right: 1px solid #fff;
    }

    .text {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}
</style>

<style>
@keyframes imp {
  0% {
    color: red;
  }
  25% {
    color: rgb(0, 255, 38);
  }
  50% {
    color: yellow;
  }
  75% {
    color: rgb(201, 4, 109);
  }
  100% {
    color: khaki;
  }
}
</style>
