<template>
  <div class="page-panel">
    <slot></slot>
  </div>
</template>

<style lang="less" scoped>
.page-panel {
  padding: 12px 16px;
  background: #fff;
}
</style>
