<template>
  <span @click="clickContent" class="content">
    {{ text }}
  </span>
  <a-modal v-model:visible="contentVisible" :title="title" :footer="null">
    {{ text }}
  </a-modal>
</template>

<script setup>
import { defineProps, ref } from "vue";

defineProps({
  text: {
    type: String,
  },
  title: {
    type: String,
    default: "详细内容",
  },
});

const contentVisible = ref(false);
const clickContent = () => {
  contentVisible.value = true;
};
</script>
<style lang="less" scoped>
.content {
  cursor: pointer;
}
</style>
