import dayjs from "dayjs";

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

Array.prototype.toTree = function (a = "superior", b = "subordinates") {
  var map = {},
    tree = [];

  for (const item of this) {
    map[item.id] = item;
  }

  for (const node of this) {
    if (node[a] && node[a].id) {
      if (map[node[a].id] !== undefined) {
        if (!map[node[a].id][b]) {
          map[node[a].id][b] = [];
        }

        map[node[a].id][b].push(node);
      }
    } else {
      tree.push(node);
    }
  }

  return tree;
};

const treeToList = (tree, childField = "children") => {
  let queen = [];
  const out = [];
  queen = queen.concat(tree);
  while (queen.length) {
    const first = queen.shift();
    if (first[childField]) {
      queen = queen.concat(first[childField]);
      // delete first[childField];
    }
    out.push(first);
  }
  return out;
};

const formatTime = (datetime, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!datetime) {
    return "/";
  }
  return dayjs(datetime).format(format);
};

const downloadFile = (obj, name, suffix) => {
  const url = window.URL.createObjectURL(new Blob([obj]));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  const fileName = name + "." + suffix;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const isClickable = (val) => val !== "" && val !== null && val !== undefined;

export { treeToList, formatTime, downloadFile, isClickable };
