import {
  asyncRoutes,
  // constantRoutes
} from "@/router/index";

import {
  // convertRouter,
  filterRoutes,
} from "@/utils/routes";

import { getAllMenus } from "@/api/menu";

const permission = {
  namespaced: true,
  state: {
    routes: [],
    sidebarRouters: [],
    menus: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes;
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    },
  },
  actions: {
    setRoutes({ commit }) {
      const finallyRoutes = filterRoutes([
        ...asyncRoutes,
        // ...constantRoutes
      ]);
      commit("SET_ROUTES", finallyRoutes);
      return [...asyncRoutes];
    },
    fetchMenus({ commit }, payload) {
      return new Promise((resolve) => {
        getAllMenus(payload).then((res) => {
          const list = res
            .filter(
              (item) =>
                item.enabled || item.type == "MENU" || item.type == "LINK"
            )
            .filter(
              (route) => (route.component = loadView(route.componentPath))
            );
          const routes = list.toTree("superior", "children");
          console.log(list);
          commit("SET_MENUS", routes);
          resolve(list);
        });
      });
    },
  },
};

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve);
};

export default permission;
