import { createApp } from "vue";
import { createPinia } from "pinia";
import * as echarts from "echarts";
import Vue3ColorPicker from "vue3-colorpicker";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import hasPermi from "@/directive/permission/hasPermi";

import PageTitle from "@/components/page/PageTitle.vue";
import PagePanel from "@/components/page/PagePanel.vue";
import PageView from "@/components/layout/PageView";
import SearchFormButtons from "@/components/layout/SearchFormButtons.vue";
import SearchWrapper from "@/components/layout/SearchWrapper";
import YButton from "@/components/common/YButton";
import YTable from "@/components/common/YTable";
import YUpload from "@/components/common/YUpload";
import YRichtext from "@/components/common/YRichtext";
import YTableDeleteButton from "@/components/common/table/YTableDeleteButton";
import YSelect from "@/components/common/YSelect.vue";
import YDrawer from "@/components/common/YDrawer.vue";
import YContentModal from "@/components/common/YContentModal.vue";
import YImage from "@/components/common/YImage.vue";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "vue3-colorpicker/style.css";

import "@/assets/css/index.less";

const app = createApp(App);

app.component("PageTitle", PageTitle);
app.component("PagePanel", PagePanel);
app.component("PageView", PageView);
app.component("SearchWrapper", SearchWrapper);
app.component("YButton", YButton);
app.component("YTable", YTable);
app.component("YUpload", YUpload);
app.component("YRichtext", YRichtext);
app.component("SearchFormButtons", SearchFormButtons);
app.component("YTableDeleteButton", YTableDeleteButton);
app.component("YSelect", YSelect);
app.component("YDrawer", YDrawer);
app.component("YContentModal", YContentModal);
app.component("YImage", YImage);

app.directive("hasPermi", hasPermi);
app.config.globalProperties.$echarts = echarts;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app
  .use(Vue3ColorPicker)
  .use(Antd)
  .use(pinia)
  .use(store)
  .use(router)
  .mount("#app");

console.log(process.env);

// window.onerror = (e) => console.log(e);

// window.addEventListener("error", (e) => {
//   console.error(e.message);
//   e.stopPropagation();
//   // prevent React's listener from firing
//   e.stopImmediatePropagation();
//   // prevent the browser's console error message
//   e.preventDefault();
// });
