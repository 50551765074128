<template>
  <div class="y-table-delete-button">
    <a-button
      type="text"
      class="ant-btn-delete"
      :disabled="disabled"
      @click.stop
      v-if="disabled"
      >{{ label }}</a-button
    >
    <a-popconfirm v-else :title="confirmTitle" @confirm="handleConfirm">
      <a-button
        type="text"
        class="ant-btn-delete"
        :disabled="disabled"
        :class="[`confirm-${type}`]"
        @click.stop
        >{{ label }}</a-button
      >
    </a-popconfirm>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  confirmTitle: {
    type: String,
    default: "确定删除吗?",
  },
  label: {
    type: String,
    default: "删除",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    validator(value) {
      return ["error", "primary"].includes(value);
    },
    default: "error",
  },
});

const emit = defineEmits("confirm");

const handleConfirm = () => {
  emit("confirm");
};
</script>

<style lang="less" scoped>
.ant-btn-delete {
  color: var(--yy-error-color) !important;
  &:hover {
    color: var(--yy-error-color) !important;
  }
}
.ant-btn-delete[disabled] {
  color: var(--yy-error-color) !important;
  opacity: 0.35;
  &:hover {
    opacity: 0.35;
    color: var(--yy-error-color) !important;
  }
}
.confirm-primary {
  color: var(--yy-primary-color) !important;
  &:hover {
    color: var(--yy-primary-color) !important;
  }
}
</style>
