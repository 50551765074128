<template>
  <a-config-provider :locale="locale">
    <template #renderEmpty>
      <div style="text-align: center; margin-top: 50px">
        <img
          src="../src/assets/images/empty-data.png"
          style="max-width: 100%"
        />
      </div>
    </template>
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

const locale = zhCN;

const route = useRoute();
const store = useStore();

// const isLogin = ref(false);

const loadRoutes = () => {
  store.dispatch("permission/setRoutes");
};

watch(
  () => route.name,
  () => {
    if (route.name == "login") {
      document.documentElement.classList.remove("light-form-wrapper");
    } else {
      document.documentElement.classList.add("light-form-wrapper");
    }
    // isLogin.value = route.name == "login";
  }
);

onMounted(() => {
  loadRoutes();
});
</script>

<style lang="less"></style>
