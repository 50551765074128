<template>
  <div class="app-view">
    <page-header></page-header>
    <div class="main-container">
      <side-menu></side-menu>
      <div class="app-main">
        <router-view :key="path" class="content-view"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useRoute } from "vue-router";

import PageHeader from "./PageHeader.vue";
import SideMenu from "./SideBar/SideMenu.vue";

export default {
  components: {
    PageHeader,
    SideMenu,
  },
  setup() {
    const route = useRoute();
    const path = ref("");

    watch(
      () => route.path,
      (val) => {
        path.value = val;
      },
      {
        immediate: true,
      }
    );

    return {
      path,
    };
  },
};
</script>

<style lang="less" scoped>
.app-view {
}
</style>
