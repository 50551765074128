import { createStore } from "vuex";

import permission from "./modules/permission";
import user from "./modules/user";

/**
 * 全局常量
 */
const globalConstant = {
  checkboxTypes: ["MULTIPLE_CHOICE"],
  radioTypes: ["ONE_CHOICE"],
  pickerTypes: ["USER_COUNTRY", "USER_PROVINCE", "USER_CITY", "USER_DISTRICT"],
  fieldTypes: [
    "FREE_RESPONSE",
    "USER_NAME",
    "USER_IDENTIFICATION",
    "USER_PHONE",
    "USER_EMAIL",
    "USER_CORPORATION",
    "USER_DUTY",
    "USER_LOCATION",
  ],
};

export default createStore({
  state: {
    ...globalConstant,
  },
  getters: {},
  mutations: {
    setCountry: (state, payload) => {
      state.country = payload;
    },
    setProvince: (state, payload) => {
      state.province = payload;
    },
    setCity: (state, payload) => {
      state.city = payload;
    },
    setDistrict: (state, payload) => {
      state.district = payload;
    },
  },
  actions: {},
  modules: {
    permission,
    user,
  },
});
