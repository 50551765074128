<template>
  <a-table
    class="y-table"
    :rowKey="rowKey"
    :scroll="scroll"
    :loading="loading"
    :pagination="false"
    :columns="calcColumns"
    :custom-row="customRow"
    :data-source="dataSource"
    :row-selection="rowSelection"
    :row-class-name="rowClass"
    :expandedRowKeys="expandedRowKeys"
    :customHeaderCell="customHeaderCell"
    :childrenColumnName="childrenColumnName"
    :expandRowByClick="expandRowByClick"
    :bordered="true"
    style="width: 100%"
    @change="handleTableChange"
    @expand="handleExpand"
    @expandedRowsChange="expandedRowsChange"
  >
    <template v-if="title" #title>{{ title }}</template>
    <template v-if="showExpanded" #expandedRowRender="{ record }">
      <slot name="expanded" :record="record" />
    </template>
    <template #bodyCell="{ text, record, index, column }">
      <template v-if="column.key == 'sequence'">
        <span v-if="column.readonly">{{ text }}</span>
        <template v-else>
          <a-button
            type="link"
            @click="showSequenceModal(record, 'sequence')"
            >{{ text || "设置" }}</a-button
          >
          <!-- v-hasPermi="sequence" -->
        </template>
      </template>
      <template v-if="column.key == 'shareSequence'">
        <span v-if="column.readonly">{{ text }}</span>
        <template v-else>
          <a-button
            type="link"
            @click="showSequenceModal(record, 'share')"
            v-hasPermi="sequence"
            >{{ text || "设置" }}</a-button
          >
        </template>
      </template>
      <template v-if="column.key === 'action'">
        <slot
          name="action"
          :text="text"
          :record="record"
          :index="index"
          :column="column"
        />
      </template>
      <template v-if="column.key === 'toggleEnabled'">
        <a-switch
          :checked="record[column.dataIndex]"
          @click="(checked) => onChangeSwitch(checked, record)"
        ></a-switch>
      </template>
      <template v-if="column.key === 'status'">
        <div v-if="text == 1 || text == 0" class="status">
          <div
            :style="{
              backgroundColor: text == 1 ? '#45dc43' : '#dddddd',
            }"
            class="status-drop"
          ></div>
          {{ text == 1 ? "在线" : "离线" }}
        </div>
        <span v-else>/</span>
      </template>

      <template v-if="column.key === 'serialNumber'">
        <a-tooltip placement="topLeft">
          <template #title>{{ text }}</template>
          {{ text }}
        </a-tooltip>
      </template>
      <template v-if="column.key === 'advType'">
        <a-tag :color="renderAdvType(record, column.dataIndex).color">{{
          renderAdvType(record, column.dataIndex).label
        }}</a-tag>
      </template>
      <template v-if="column.key === 'documentType'">
        <a-tag :color="renderDocumentType(record, column.dataIndex).color">{{
          renderDocumentType(record, column.dataIndex).label
        }}</a-tag>
      </template>
      <template v-if="column.key === 'imageList'">
        <template v-if="getPreviewImages(record, column.dataIndex)">
          <a-image
            v-if="getPreviewImages(record, column.dataIndex).length"
            :width="100"
            :preview="{ visible: false }"
            :src="getPreviewImages(record, column.dataIndex)[0]"
            @click="previewVisible = true"
          ></a-image>
          <template v-else>/</template>
        </template>
        <template v-else>/</template>
      </template>
      <template v-if="column.key == 'img'">
        <!-- <template v-if="Array.isArray(column.dataIndex)">
          <a-image
            v-if="getFileUrl(record, column.dataIndex.join('.'))"
            :width="100"
            :src="getFileUrl(record, column.dataIndex.join('.'))"
          />
        </template>
        <template v-else>
          <a-image
            v-if="record[column.dataIndex]"
            :width="100"
            :src="record[column.dataIndex]"
          />
          <span v-else>/</span>
        </template>-->
        <y-image :text="text"></y-image>
      </template>
      <template v-if="column.key == 'link'">
        <a-button type="link" v-if="text" @click="toLink(text)">跳转</a-button>
        <span v-else>/</span>
      </template>
      <template v-if="column.key == 'html'">
        <a-button type="link" v-if="text" @click="showHtml(record)"
          >查看</a-button
        >
        <span v-else>/</span>
      </template>
      <template v-if="column.key == 'datetime'">{{
        renderDatetime(text)
      }}</template>
      <template v-if="column.key === 'index'">
        {{ (current - 1) * pageSize + index + 1 }}
      </template>
      <slot
        name="custom"
        :column="column"
        :record="record"
        :text="text"
        :index="index"
      ></slot>
    </template>
  </a-table>
  <div class="pagination-box" v-if="showPagination">
    <a-pagination
      show-quick-jumper
      v-model:current="current"
      v-model:pageSize="size"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChange"
    ></a-pagination>
  </div>
  <a-modal
    width="400px"
    :visible="modalVisible"
    title="权重设置"
    @ok="handleSubmitSequence"
    @cancel="onCancel"
    :confirmLoading="confirmLoading"
  >
    <a-form
      ref="formRef"
      :model="modalForm"
      :rules="rules"
      class="light-form-wrapper"
    >
      <a-form-item label="权重" name="sequence">
        <a-input-number
          :min="1"
          :precision="0"
          v-model:value="modalForm.sequence"
        ></a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    title="内容预览"
    width="500px"
    :footer="null"
    :visible="htmlModalVisible"
    @cancel="onCancel"
  >
    <template v-if="selectedRow && selectedRow.content">
      <div class="html" v-html="selectedRow.content"></div
    ></template>
  </a-modal>
  <div style="display: none">
    <a-image-preview-group
      :preview="{
        visible: previewVisible,
        onVisibleChange: (vis) => (previewVisible = vis),
      }"
    >
      <a-image v-for="url in imageUrls" :key="url" :src="url" />
    </a-image-preview-group>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import { toRefs, reactive, computed, ref } from "vue";
export default {
  name: "YTable",
  emits: ["change", "update:curr-page", "update:page-size"],
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    expandedRowKeys: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showExpanded: {
      type: Boolean,
      default: false,
    },
    currPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    rowSelection: {
      type: Object || undefined,
      default: undefined,
    },
    customRow: {
      type: Function,
      default: () => {},
    },
    // 通过点击行来展开子行
    expandRowByClick: {
      type: Boolean,
      default: false,
    },
    childrenColumnName: {
      type: String,
      default: "children",
    },
    scroll: {
      type: Object,
      default: () => {
        return {};
      },
    },
    title: {
      type: String,
    },
    rowClassName: {
      type: String,
    },
    sequence: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formRef = ref();
    const previewVisible = ref(false);
    const imageUrls = ref([]);
    const sequenceType = ref();
    const selectedRow = ref();
    const modalVisible = ref(false);
    const htmlModalVisible = ref(false);
    const confirmLoading = ref(false);
    const modalForm = reactive({});
    const state = reactive({
      current: computed({
        get: () => {
          return props.currPage + 1;
        },
        set: (val) => {
          console.log(val);
          emit("update:curr-page", val - 1);
        },
      }),
      size: computed({
        get: () => {
          return props.pageSize;
        },
        set: (val) => {
          emit("update:page-size", val);
        },
      }),
      rowClass: (_record, index) => {
        let classNameList = [];
        if (props.rowClassName) {
          classNameList.push("clickable-row");
        }
        if (props.striped) {
          if (index % 2 == 1) {
            classNameList.push("striped-row");
          }
        }
        return classNameList.join(" ");
        // return props.striped ? (index % 2 === 1 ? "striped-row" : null) : "";
      },
      customHeaderCell: (column) => {
        console.log(column);
        return {};
      },
      handleChange: (pagination) => {
        emit("change", pagination);
      },
      hasChild: computed(() => {
        return (
          props.columns.findIndex((i) => {
            if (i.children !== undefined) return i;
          }) !== -1
        );
      }),
      renderAdvType(record, key) {
        const map = {
          PICTURE: {
            label: "图片广告",
            color: "red",
          },
          EXHIBIT: {
            label: "展品广告",
            color: "orange",
          },
          EXHIBITOR: {
            label: "展商广告",
            color: "cyan",
          },
        };
        if (record[key]) {
          return map[record[key]];
        }
        return null;
      },
      renderDocumentType(record, key) {
        const map = {
          HTML: {
            label: "富文本",
            color: "red",
          },
          PDF: {
            label: "PDF文件",
            color: "orange",
          },
          WORD: {
            label: "文档",
            color: "cyan",
          },
        };
        if (record[key]) {
          return map[record[key]];
        }
        return null;
      },
    });

    const rules = {
      sequence: {
        required: true,
        message: "请输入权重",
      },
    };

    const onChangeSwitch = (checked, record) => {
      emit("switch", {
        checked,
        record,
      });
    };

    const expandedRowsChange = (expandedRow) => {
      emit("expandedRowsChange", expandedRow);
    };

    const handleExpand = (expanded, record) => {
      console.log(expanded, record);
      emit("expand", { expanded, ...record });
    };

    const showSequenceModal = (record, type) => {
      sequenceType.value = type;
      selectedRow.value = record;
      modalVisible.value = true;
      modalForm.sequence =
        type == "share" ? record.orderNumber : record.sequence;
    };

    const handleSubmitSequence = async () => {
      await formRef.value.validate();
      if (sequenceType.value == "share") {
        emit("changeShareSequence", {
          ...selectedRow.value,
          orderNumber: modalForm.sequence,
        });
      } else {
        emit("changeSequence", {
          ...selectedRow.value,
          sequence: modalForm.sequence,
        });
      }
      formRef.value.resetFields();
      onCancel();
    };

    const onCancel = () => {
      selectedRow.value = null;
      modalVisible.value = false;
      htmlModalVisible.value = false;
    };

    const toLink = (url) => {
      window.open(url);
    };

    const renderDatetime = (val) => {
      if (val) {
        return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
      }
      return "/";
    };

    const showHtml = (record) => {
      selectedRow.value = record;
      htmlModalVisible.value = true;
    };

    const getFileUrl = (record, key) => {
      return _.get(record, key);
    };

    const getPreviewImages = (record, key) => {
      const value = _.get(record, key);
      if (Array.isArray(value)) {
        imageUrls.value = value;
        return value;
      } else {
        if (value) {
          imageUrls.value = [value];
          return [value];
        }
        return null;
      }
    };

    const handleTableChange = (pag, filters, sorter) => {
      console.log(sorter);
      emit("sort", sorter);
    };

    return {
      previewVisible,
      imageUrls,
      selectedRow,
      formRef,
      confirmLoading,
      modalVisible,
      htmlModalVisible,
      modalForm,
      rules,
      ...toRefs(state),
      calcColumns: computed(() =>
        props.columns.map((item) => {
          if (!item.align) {
            item["align"] = "center";
          }
          return item;
        })
      ),
      handleExpand,
      expandedRowsChange,
      onChangeSwitch,
      showSequenceModal,
      handleSubmitSequence,
      onCancel,
      toLink,
      renderDatetime,
      showHtml,
      getFileUrl,
      getPreviewImages,
      handleTableChange,
    };
  },
};
</script>

<style lang="less" scoped>
.pagination-box {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}
// ::v-deep {
//   .ant-table-cell {
//     display: flex;
//   }
// }

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  .status-drop {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>
