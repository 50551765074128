<template>
  <a-drawer
    maskClosable
    destroyOnClose
    :title="title"
    :width="width"
    :visible="visible"
    class="y-drawer"
    @close="onCancel"
  >
    <template v-if="footer" #footer>
      <div class="flex" style="gap: 15px">
        <a-button
          v-if="buttons.indexOf('cancel') > -1"
          type="default"
          class="ant-btn-reset"
          @click="onCancel"
          >{{ cancelText }}</a-button
        >
        <a-button
          v-if="buttons.indexOf('ok') > -1"
          type="primary"
          :loading="confirmLoading"
          @click="onOk"
          >{{ okText }}</a-button
        >
      </div>
    </template>
    <slot></slot>
  </a-drawer>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  buttons: {
    type: Array,
    default: () => ["ok", "cancel"],
  },
  confirmLoading: {
    type: Boolean,
    default: false,
  },
  footer: {
    type: Boolean,
    default: true,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [String, Number],
    default: 800,
  },
  title: {
    type: String,
  },
  okText: {
    type: String,
    default: "保存",
  },
  cancelText: {
    type: String,
    default: "关闭",
  },
});

const emit = defineEmits(["ok", "close"]);

const onOk = () => {
  emit("ok");
  emit("update:visible", false);
};

const onCancel = () => {
  emit("close");
  emit("update:visible", false);
};
</script>
